.taggd {
  position: relative;
}

.taggd__image {
  display: block;
  height: auto;
  width: 100%;
  margin: 0;
}

.taggd__wrapper {
  display: block;
  height: 1em;
  width: 1em;
  position: absolute;
}

.taggd__button {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, .25);
  background-image: none;
  text-align: left;
}

.taggd__popup {
  position: absolute;
  left: 0;
}
